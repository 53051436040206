@import '../../../global.scss';

.email-text {
  top: 50%;
  transform: translateY(-60%);

  @include media-breakpoint-down(md) {
    top: 0;
    left: 0;
    transform: none;
  }
}