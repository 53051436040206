.account {
  form {
    button,
    button:hover,
    button:active,
    button:focus {
      border: 1px solid #ced4da !important;
      border-left: 0px !important;
    }
    
    .form-control:focus {
      box-shadow: none !important;
      border: 1px solid #ced4da !important;
      border-right: 0px !important;
    }
  }
}