.quantity-picker {
  p {
    min-width: 40px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn:disabled {
    opacity: 0.4;
  }
}