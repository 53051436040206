@import '../../../global.scss';

.checkout {
  .donation {
    width: 100%;
  }
  
  span {
    text-decoration-color: $danger !important;
    text-decoration-thickness: 2px !important;
  }
}
