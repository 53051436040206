$primary: #1A0001;
$secondary: #34120C;
$light: #ffffff;
$dark: #000000;

$font-family-base: 'Montserrat';
$font-weight-lighter: 100;
$font-weight-bolder: 900;

body {
  --bs-secondary-rgb: 52,18,12;
}

.w-fit-content {
  width: fit-content
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

@import "~bootstrap/scss/bootstrap";

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      $color: $light,
      $hover-color: $value,
      $hover-background: $light,
      $hover-border: $value,
      $active-color: $value,
      $active-background: $light,
      $active-border: $value
    );
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fs-md-1 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2.5 !important;
  }
}

.fs-md-2 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 2 !important;
  }
}

.fs-md-3 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.75 !important;
  }
}

.fs-md-4 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.5 !important;
  }
}

.fs-md-5 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 1.25 !important;
  }
}

.fs-md-6 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base !important;
  }
}

.fs-7 {
  font-size: $font-size-base * 0.875 !important;
}

.fs-md-7 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 0.875 !important;
  }
}

.fs-8 {
  font-size: $font-size-base * 0.75 !important;
}

.fs-md-8 {
  @include media-breakpoint-down(md) {
    font-size: $font-size-base * 0.75 !important;
  }
}

.w-md-25 {
  @include media-breakpoint-down(md) {
   width: 25% !important;
  }
}

.w-md-50 {
  @include media-breakpoint-down(md) {
   width: 50% !important;
  }
}

.w-md-75 {
  @include media-breakpoint-down(md) {
   width: 75% !important;
  }
}

.w-85 {
  width: 85% !important;
}

.w-md-85 {
  @include media-breakpoint-down(md) {
   width: 85% !important;
  }
}

.w-md-100 {
  @include media-breakpoint-down(md) {
   width: 100% !important;
  }
}

.border-md-end-0 {
  @include media-breakpoint-down(md) {
    border-right: none !important;
  }
}

.border-md-end {
  @include media-breakpoint-down(md) {
    border-right: var(--bs-border-width) var(--bs-border-style) !important;
  }
}

.border-md-bottom {
  @include media-breakpoint-down(md) {
    border-bottom: var(--bs-border-width) var(--bs-border-style) !important;
  }
}

.bg-white-50 {
  background-color: rgba(255,255,255,0.8);
}

.opacity-40 {
  opacity: 0.4 !important;
}