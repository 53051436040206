.subtotal {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
}

.arrow {
  transform: rotate(180deg);

  path {
    fill: #ffffff;
  }
}