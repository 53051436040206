@import '../../global.scss';

.footer-container {
  flex-direction: column;

  .social-wrapper {
    img {
      width: 37px;
      height: 37px;
    }
  }

  .logo-wrapper {
    width: 133px;
    height: 133px;
  }
  
  .whatsapp {
    width: 37px;
    height: 37px;  
  }
}