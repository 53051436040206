@import '../../../global.scss';

.top-wrapper {
  border-width: 2px !important;
  border-style: solid;

  @include media-breakpoint-down(md) {
    border-width: 0px !important;
  }
}

.dropdown {
  .dropdown-toggle,
  .dropdown-menu {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 60%;
    }
  }

  .dropdown-toggle {
    &::after {
      position: absolute;
      top: calc(50% - 2px);
      right: 16px;
    }
  }
}

.quantity-wrapper {
  .quantity-picker {
    width: fit-content;
  }
}

.description {
  p {
    margin-top: 16px;
  }
}