@import '../../global.scss';

.page-title-container {
  height: 200px;
  z-index: 1;

  @include media-breakpoint-down(md) {
    height: 100px;
  }
  
  p {
    font-size: $font-size-base * 3.75;
    z-index: 2;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: $font-size-base * 2;
    }
  }

  .design-bottom {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}