@import '../../../global.scss';

.gallery-wrapper {
  border-width: 0px !important;
  border-style: solid;

  @include media-breakpoint-down(md) {
    border-width: 2px !important;
  }
}

.images-wrapper {
  max-height: 430px;
  overflow-y: scroll;
  
  @include media-breakpoint-down(md) {
    overflow-y: none;
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  div {
    border: 1px solid black;
    width: 100%;
    max-width: 70px;
    height: auto;
  }
}

.main-image {
  border: 1px solid black;
  
  @include media-breakpoint-down(md) {
    border: 0;
  }

  img {
    width: 100%;
    max-width: 430px;
    height: auto;
  }
}